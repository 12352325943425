<template>
  <div>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title> Projekt "{{ projectToDelete.name }}" löschen? </v-card-title>
        <v-card-subtitle> Dies kann nicht rückgängig gemacht werden </v-card-subtitle>
        <v-card-actions>
          <v-spacer />
          <v-btn color="secondary" text @click="dialogDelete = false"> Abbrechen </v-btn>
          <v-btn color="red" text @click="deleteProjectOk(projectToDelete)"> Entgültig löschen </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid class="pa-6 d-none d-sm-block">
      <v-row>
        <v-col cols="12" md="12">
          <h2 class="mb-2">Neues Projekt anlegen</h2>
          <v-row class="d-flex">
            <v-card v-for="projectType in projectTypes" :key="projectType.id" elevation="0" class="my-5 mr-5">
              <v-btn
                :class="`new-project-btn ${projectType.name.toLowerCase()}`"
                data-cy="v-btn_new-project"
                @click="createProject(projectType.id)"
              >
                <div style="width: 100%" class="d-flex align-center px-5">
                  <v-icon
                    class="px-2 py-2 mr-2"
                    style="border: 1px solid rgba(0, 0, 0, 0.2); border-radius: 50%"
                    :style="{ 'background-color': projectType.color }"
                    large
                  >
                    {{ projectType.icon }}
                  </v-icon>
                  <span class="btn-text">{{ projectType.displayName }}</span>
                </div>
              </v-btn>
            </v-card>
          </v-row>
        </v-col>
        <v-spacer />
      </v-row>
      <v-row>
        <v-col>
          <h2>Alle Projekte</h2>
          <v-layout column class="dashboardTable">
            <v-flex style="overflow: auto">
              <v-data-table
                :items="projects"
                :headers="headers"
                :loading="loadingProjects"
                :search="search"
                :options="options"
                :server-items-length="totalCount"
                @click:row="openProject"
                @update:options="updateOptions"
              >
                <template #top>
                  <v-toolbar flat>
                    <v-spacer />
                    <v-col cols="6">
                      <v-text-field
                        v-model="search"
                        hide-details
                        label="Projekt suchen"
                        prepend-inner-icon="mdi-magnify"
                        clearable
                        @keyup.enter="updateSearch"
                        @blur="updateSearch"
                      />
                    </v-col>
                  </v-toolbar>
                </template>
                <template #item.actions="{ item }">
                  <!-- TODO: reimplement archive
                  <v-menu offset-y>
                    <template #activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item @click="archiveProject(item)">
                        <v-list-item-content>
                          <v-list-item-title>Archivieren</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>-->
                  <v-btn icon @click.stop="deleteProjectRequest(item)">
                    <v-icon small> mdi-delete </v-icon>
                  </v-btn>
                </template>

                <template #no-data>
                  <div v-if="errorLoadingProjects" class="ma-6">
                    <p>
                      Daten konnten nicht geladen werden. Überprüfe deine Internetverbindung oder deine Anmeldedaten
                    </p>
                    <v-btn class="pl-5" color="primary" outlined @click="tryLoadProjects">
                      <v-icon>mdi-cached</v-icon> Neu Laden
                    </v-btn>
                  </div>
                  <span v-else-if="search === ''">
                    Keine Projekte vorhanden. Erstelle neue Projekte, um hier einen Überblick zu erhalten.
                  </span>
                  <span v-else> Keine Projekte mit Titel {{ usedSearch }} gefunden. </span>
                </template>

                <template #item.type="{ item }">
                  <v-tooltip top>
                    <template #activator="{ attrs, on }">
                      <v-icon
                        class="pa-2"
                        :style="{ backgroundColor: get(projectTypes, item.type, 'color'), borderRadius: '50%' }"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ get(projectTypes, item.type, "icon") }}
                      </v-icon>
                    </template>
                    {{ getName(projectTypes, item.type) }}
                  </v-tooltip>
                </template>

                <!-- <template #item.status="{ item }">
                  <v-chip text-color="white" :color="get(projectStatus, item.status, 'color')">
                    {{ get(projectStatus, item.status, "name") }}
                  </v-chip>
                </template> -->

                <template #item.status="{ item }">
                  <v-chip text-color="black" color="transparent">
                    <v-icon
                      :style="{
                        color: get(projectStatus, item.status, 'color'),
                        borderRadius: '50%',
                        width: '16px',
                        height: '16px',
                      }"
                      class="mr-2"
                    >
                      mdi-checkbox-blank-circle
                    </v-icon>
                    {{ get(projectStatus, item.status, "name") }}
                  </v-chip>
                </template>

                <template #item.startDate="{ item }">
                  {{
                    new Date(item.startDate).toLocaleDateString("de-DE", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  }}
                </template>

                <template #item.customer="{ item }">
                  <template v-if="item.customer.contact != null">
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-account</v-icon>
                      </template>
                      Privatkunde
                    </v-tooltip>
                    {{ item.customer.contact.name }}
                  </template>
                  <template v-else>
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-briefcase</v-icon>
                      </template>
                      Firmenkunde
                    </v-tooltip>
                    {{ item.customer.name }}
                  </template>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="pa-6 d-sm-none">
      <v-col>
        <h2 class="mb-2">Neues Projekt anlegen</h2>
        <v-row class="d-flex flex-column">
          <v-card v-for="projectType in projectTypes" :key="projectType.id" elevation="0" class="mt-5">
            <v-btn
              :class="`new-project-btn ${projectType.name.toLowerCase()}`"
              data-cy="v-btn_new-project"
              @click="createProject(projectType.id)"
            >
              <div style="width: 100%" class="d-flex justify-space-between align-center px-5">
                <v-icon
                  class="pa-2 mr-5"
                  style="border: 1px solid rgba(0, 0, 0, 0.2); border-radius: 50%"
                  :style="{ 'background-color': projectType.color }"
                  large
                >
                  {{ projectType.icon }}
                </v-icon>
                <span class="btn-text">{{ projectType.displayName }}</span>
              </div>
            </v-btn>
          </v-card>
        </v-row>
      </v-col>
      <v-row class="mt-5">
        <v-col>
          <div class="d-flex justify-space-between align-center">
            <h2 v-show="!searchActive">Alle Projekte</h2>
            <v-toolbar flat>
              <v-spacer />

              <transition name="slide">
                <v-text-field
                  v-if="searchActive"
                  ref="searchInput"
                  v-model="search"
                  style="width: 100%"
                  hide-details
                  label="Projekt suchen"
                  @keyup.enter="updateSearch"
                  @blur="updateSearch"
                />
              </transition>
              <v-btn v-if="!searchActive" icon @click="showFilter = !showFilter">
                <v-icon>mdi-sort-alphabetical-ascending</v-icon>
              </v-btn>
              <v-btn icon @click="toggleSearchField">
                <v-icon>{{ searchActive ? "mdi-close" : "mdi-magnify" }}</v-icon>
              </v-btn>
            </v-toolbar>
          </div>
          <v-container fluid class="ma-0 pa-0">
            <v-row no-gutters>
              <!-- <v-col cols="6" align-self="center">
                <v-select
                  :value="options.sortBy[0]"
                  :items="sortableHeaders"
                  item-text="text"
                  item-value="value"
                  label="Sortieren"
                  dense
                  outlined
                  hide-details
                  @change="updateSort"
                />
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="5">
                <v-checkbox :value="options.sortDesc[0]" label="Absteigend" @change="updateSortType"></v-checkbox>
              </v-col> -->
              <v-fade-transition>
                <v-row v-show="showFilter">
                  <v-col cols="6" align-self="center">
                    <v-select
                      :value="options.sortBy[0]"
                      :items="sortableHeaders"
                      item-text="text"
                      item-value="value"
                      label="Sortieren"
                      dense
                      outlined
                      hide-details
                      @change="updateSort"
                    />
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="5">
                    <v-checkbox :value="options.sortDesc[0]" label="Absteigend" @change="updateSortType"></v-checkbox>
                  </v-col>
                </v-row>
              </v-fade-transition>
              <v-col class="text-center">
                <v-btn icon :disabled="options.page === 1" @click="updatePageNumber(options.page - 1)">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </v-col>

              <v-spacer></v-spacer>
              <v-col class="text-center">
                <v-btn
                  icon
                  :disabled="options.page === Math.ceil(totalCount / options.itemsPerPage)"
                  @click="updatePageNumber(options.page + 1)"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col v-if="loadingProjects" class="text-center">
                <v-progress-circular indeterminate size="50"></v-progress-circular>
              </v-col>
              <v-col v-else>
                <project-card
                  v-for="(project, index) in projects"
                  :key="index"
                  :project="project"
                  :headers="headers"
                  :expanded-card="index === expandedIndex"
                  :search="search"
                  @open="openProject"
                  @delete="deleteProjectRequest"
                  @update="archiveProject"
                  @create="createProject"
                  @expand="(value) => expandProjectCard(value, index)"
                />
              </v-col>
            </v-row>
            <v-row v-show="!loadingProjects" no-gutters>
              <v-col class="text-center">
                <v-btn icon :disabled="options.page === 1" @click="updatePageNumber(options.page - 1)">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </v-col>

              <v-spacer></v-spacer>
              <v-col class="text-center">
                <v-btn
                  icon
                  :disabled="options.page === Math.ceil(totalCount / options.itemsPerPage)"
                  @click="updatePageNumber(options.page + 1)"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Enums from "@/mixins/enums";
import projectCard from "@/components/core/cards/projectCard";
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import ProjectApi from "@/Repository/Project.api";
import alertVue from "@/mixins/alert.vue";
export default {
  name: "ProjectView",
  components: {
    projectCard,
  },
  mixins: [Enums, alertVue],
  layout: "dashboard",
  data: () => ({
    // projectTypeId: 0,
    showFilter: false,
    expandedIndex: -1,
    search: "",
    usedSearch: "",
    headers: [
      {
        text: "Typ",
        value: "type",
        sortable: false,
        width: 20,
      },
      {
        text: "Status",
        value: "status",
        filterable: true,
        width: 40,
      },
      {
        text: "Erstellt am",
        align: "start",
        value: "startDate",
        width: 200,
      },
      {
        text: "Projektname",
        align: "start",
        value: "name",
      },
      {
        text: "Kunde",
        align: "start",
        value: "customer",
        sortable: false,
        width: 300,
      },
      {
        text: "Aktionen",
        value: "actions",
        width: 100,
      },
    ],
    projects: [],
    dialogDelete: false,
    projectToDelete: {
      id: 0,
      name: "",
    },
    options: {
      page: 1,
      itemsPerPage: 15,
      sortBy: ["startDate"],
      sortDesc: [true],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    },
    loadingProjects: true,
    errorLoadingProjects: false,
    sortOrder: null,
    totalCount: 0,
    searchActive: false,
  }),
  computed: {
    ...mapState("project", {
      project: (state) => state.activeProject,
      projectTypeId: (state) => state.activeProject.projectTypeId,
    }),
    ...mapGetters("project", ["getProjectTypeId"]),
    requestParams: function () {
      return {
        pageNumber: this.options.page,
        pageSize: this.options.itemsPerPage,
        filter: this.usedSearch,
        sortBy: this.options.sortBy.length === 0 ? null : this.options.sortBy[0],
        sortDesc: this.options.sortDesc.length === 0 ? null : this.options.sortDesc[0],
      };
    },
    sortableHeaders: function () {
      // headers are sortable if not set to false (null is true)
      return this.headers.filter((header) => header.sortable !== false);
    },
  },
  mounted() {
    this.tryLoadProjects();
  },
  methods: {
    ...mapActions("project", ["setActiveProjectById"]),
    ...mapActions("company", ["updateProject"]),
    ...mapMutations("project", ["resetProject", "setProjectTypeId"]),
    updateSortType(descending) {
      console.log("UPDATE SORT TYPE");
      this.options.sortDesc = [descending];
      this.options.page = 1;
      this.tryLoadProjects();
    },
    updatePageNumber(number) {
      console.log("updatePageNumber");
      this.options.page = number;
      this.tryLoadProjects();
    },
    updateSort(value) {
      console.log("UPDATE SORT");
      this.options.sortBy = [value];
      this.options.sortDesc = [false]; //TODO
      this.options.page = 1;
      this.tryLoadProjects();
    },
    updateOptions(value) {
      console.log("updateOptions");

      if (this.options.page === value.page) {
        value.page = 1;
      }
      this.options = value;
      this.tryLoadProjects();
    },
    updateSearch() {
      if (this.usedSearch === this.search) return;

      this.usedSearch = this.search;
      this.tryLoadProjects();
    },
    tryLoadProjects() {
      this.loadingProjects = true;
      ProjectApi.get(this.requestParams)
        .then((result) => {
          this.projects = result.data.items;
          this.options.page = result.data.pageNumber;
          this.options.itemsPerPage = result.data.pageSize;
          this.totalCount = result.data.totalCount;
          this.errorLoadingProjects = false;
        })
        .catch((error) => {
          this.errorLoadingProjects = true;
          console.error("Error loading projects: ", error);
        })
        .finally(() => (this.loadingProjects = false));
    },
    openProject(item) {
      this.$router.push("/projectStartOverview?id=" + item.id);
    },
    deleteProjectRequest(item) {
      this.projectToDelete = item;
      this.dialogDelete = true;
    },
    deleteProjectOk(item) {
      ProjectApi.delete(item.id)
        .then(() => {
          this.tryLoadProjects();
          this.showSuccessMessage("Projekt wurde gelöscht", "");
        })
        .catch(() => {
          this.showFailedMessage("Projekt konnte nicht gelöscht werden", "");
          console.error("Error deleting project");
        });
      this.dialogDelete = false;
    },
    archiveProject(item) {
      this.updateProject({ id: item.id, status: "done" });
    },
    createProject(typeId) {
      this.projectTypeId = typeId;
      this.setProjectTypeId(typeId);
      let targetRoute = "/project";
      if (typeId === 2) targetRoute = "/quantitySurveyProject";
      this.$nuxt.$router.push({ path: targetRoute, query: { projectTypeId: typeId } });
      this.resetProject();
      // this.$nuxt.$router.push("/project");
    },
    importProject() {
      this.resetProject();
      this.$nuxt.$router.push("/quantitySurveyProject");
    },
    toggleSearchField() {
      this.searchActive = !this.searchActive;

      if (this.searchActive) {
        this.$nextTick(() => {
          this.$refs.searchInput.focus();
          this.showFilter = false;
        });
      } else {
        this.search = "";
        this.updateSearch();
      }
    },
    expandProjectCard(value, index) {
      if (value) this.expandedIndex = index;
      else this.expandedIndex = -1;
    },
  },
};
</script>

<style scoped>
.dashboardTable {
  height: 70vh;
}
.body {
  overflow: hidden;
  overflow-y: hidden;
}

.new-project-container {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  justify-items: center;
  align-items: center;
}

.new-project-btn {
  background-color: rgba(255, 255, 255) !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100% !important;
  height: 100px !important;
  border: none;
  padding: 0 !important;
}

.btn-text {
  text-transform: capitalize;
  text-align: start;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  width: 100%;
  display: block;
}

.btn-disabled-text {
  color: gray;
}

.aufmass {
  cursor: not-allowed;
}

@supports (height: 100dvh) {
  .dashboardTable {
    height: 60dvh;
  }
}
</style>
