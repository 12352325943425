<template>
  <v-card style="margin: 0 5px" class="my-4" @click="openProject">
    <div class="d-flex flex-row align-center justify-space-between w-100">
      <v-card-title>
        <div class="d-flex flex-column">
          <div class="d-flex align-center">
            <v-icon
              class="px-1 py-1"
              :style="{ 'background-color': projectTypeColor, 'border-radius': '50%' }"
              color="#000000"
            >
              {{ get(projectTypes, project.type, "icon") }}
            </v-icon>
            <p class="project-title ml-1">{{ truncatedProjectName }}</p>
          </div>
          <p class="project-date">Erstellt am: {{ formatDate(project.startDate) }}</p>
          <div class="project-status">
            Status:
            <v-icon small :color="get(projectStatus, project.status, 'color')" class="ml-2 mr-1">
              mdi-checkbox-blank-circle
            </v-icon>
            {{ get(projectStatus, project.status, "name") }}
          </div>
        </div>
      </v-card-title>
      <v-btn large text icon @click.stop="toggleDetails">
        <v-icon large>{{ expandedCard ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
    </div>
    <v-expand-transition>
      <transition name="custom-slide-fade">
        <div v-show="expandedCard">
          <v-divider style="margin: 0 15px"></v-divider>
          <v-card-title>
            <div class="d-flex flex-column">
              <p class="project-address">Adresse: {{ project.address }}</p>
              <p v-if="project.customer.contact != null" class="project-customer">
                Projektleitung: {{ project.customer.contact.name }}
              </p>
              <p v-else class="project-customer">Projektleitung: {{ project.customer.name }}</p>
            </div>
          </v-card-title>
          <v-card-actions class="d-flex justify-end flex-row align-center">
            <v-btn icon color="black" text @click.stop="openMaps"> <v-icon>mdi-directions</v-icon> </v-btn
            ><!-- TODO: reimplement archive
            <v-btn icon color="green" text @click.stop="archiveProject">
              <v-icon>mdi-archive-outline</v-icon>
            </v-btn>-->
            <v-btn icon color="red" text @click.stop="deleteProject">
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </div>
      </transition>
    </v-expand-transition>
  </v-card>
</template>

<script>
import Enums from "@/mixins/enums.vue";
// import googleMapsMixin from "@/mixins/googleMapsMixin";
export default {
  name: "ProjectCard",
  mixins: [Enums],
  props: {
    project: {
      type: Object,
      required: true,
    },
    expandedCard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMobileView: false,
      search: "",
    };
  },
  computed: {
    googleMapsUrl() {
      var lat = parseFloat(this.project.coordinates.split(",")[0].split(":")[1].trim());
      var lng = parseFloat(this.project.coordinates.split(",")[1].split(":")[1].trim());
      console.log(lat, lng);
      const address = encodeURIComponent(lat + ", " + lng);
      return `https://www.google.com/maps/search/?api=1&query=${address}`;
    },
    appleMapsUrl() {
      var lat = parseFloat(this.project.coordinates.split(",")[0].split(":")[1].trim());
      var lng = parseFloat(this.project.coordinates.split(",")[1].split(":")[1].trim());
      console.log(lat, lng);
      const address = encodeURIComponent(lat + ", " + lng);
      return `https://maps.apple.com/?q=${address}`;
    },
    truncatedProjectName() {
      return this.project.name.length > 16 ? this.project.name.slice(0, 16) + "..." : this.project.name;
    },
    projectTypeColor() {
      const projectType = this.projectTypes.find((type) => type.id === this.project.type);
      return projectType ? projectType.color : "transparent";
    },
  },
  methods: {
    openProject() {
      this.$emit("open", this.project);
    },
    toggleDetails() {
      this.$emit("expand", !this.expandedCard);
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    },
    deleteProject() {
      this.$emit("delete", this.project);
    },
    archiveProject() {
      this.$emit("update", this.project);
    },
    createProject() {
      this.$emit("create", this.project);
    },
    openMaps(e) {
      try {
        e.stopPropagation(); // Prevent triggering the click event of the parent component
        if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
          window.location = this.appleMapsUrl;
          const startTime = new Date().getTime();
          setTimeout(() => {
            if (new Date().getTime() - startTime < 1000) {
              window.location = this.googleMapsUrl;
            }
          }, 1000);
        } else {
          window.open(this.googleMapsUrl);
        }
      } catch (error) {
        console.log("cought error inopen Maps! ", error);
      }
    },
  },
};
</script>

<style scoped>
.project-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0 !important;
}
.project-date {
  font-size: 14px;
  margin-bottom: 0 !important;
}
.project-status {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
}
.project-address {
  font-size: 14px;
  margin-bottom: 0 !important;
  word-wrap: normal;
}
.project-customer {
  font-size: 14px;
  margin-bottom: 0 !important;
}
.custom-slide-fade-enter-active {
  transition: all 0.3s ease;
}
.custom-slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.custom-slide-fade-enter,
.custom-slide-fade-leave-to {
  transform: translateY(-100px);
  opacity: 0;
}
.custom-slide-fade-leave,
.custom-slide-fade-enter-to {
  transform: translateY(0);
  opacity: 1;
}
</style>
