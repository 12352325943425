<script>
export default {
  name: "Alert",
  data() {
    return {
      defaultMessageDuration: 4000,
    };
  },
  methods: {
    showFailedLoadMessage(itemKind) {
      if (this.$isServer) return;
      this.$swal.fire({
        position: "bottom-end",
        icon: "info",
        title: itemKind + " " + " konnten nicht geladen werden",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: this.defaultMessageDuration,
        toast: true,
        showClass: {
          popup: "animate__animated animate__fadeInLeft",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    },
    showFailedMessage(title, text) {
      if (this.$isServer) return;
      this.$swal.fire({
        position: "bottom-end",
        icon: "error",
        title: title,
        text: text,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: this.defaultMessageDuration,
        toast: true,
        showClass: {
          popup: "animate__animated animate__fadeInLeft",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    },

    showWarnMessage(title, message) {
      if (this.$isServer) return;
      this.$swal.fire({
        position: "bottom-end",
        icon: "warning",
        title: title,
        text: message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: this.defaultMessageDuration,
        toast: true,
        showClass: {
          popup: "animate__animated animate__fadeInLeft",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    },

    showSucessToast(title, message) {
      if (this.$isServer) return;
      this.$swal.fire({
        position: "bottom-end",
        icon: "success",
        title: title,
        text: message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: this.defaultMessageDuration,
        toast: true,
        showClass: {
          popup: "animate__animated animate__fadeInLeft",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    },
    showSuccessMessage(title, message) {
      this.showSucessToast(title, message);
    },
    showInfoToast(title, message, time = this.defaultMessageDuration) {
      if (this.$isServer) return;
      this.$swal.fire({
        position: "bottom-end",
        icon: "info",
        title: title,
        text: message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: time,
        toast: true,
        showClass: {
          popup: "animate__animated animate__fadeInLeft",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    },
    showErrorToast(title, message, fatal) {
      if (this.$isServer) return;
      this.$swal.fire({
        position: "bottom-end",
        icon: "error",
        title: title,
        text: message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: fatal ? null : this.defaultMessageDuration,
        showCloseButton: fatal,
        toast: true,
        showClass: {
          popup: "animate__animated animate__fadeInLeft",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    },
    async showVendocLogInDialog() {
      // make sure to allways close vuetify dialogs before opening
      // otherwise the inpusts can not be focused!
      if (this.$isServer) return;

      let user = {};
      user = await this.$swal
        .fire({
          html: `
              <div class="imgcontainer">
              <img src="VenDoc-logo.png" alt="vendoc logo" class="avatar" height="100">
            </div>
            <input type="text" id="login" class="swal2-input front" placeholder="Benutzername">
            <input type="password" id="password" class="swal2-input front" placeholder="Passwort">`,
          confirmButtonText: "Anmelden",
          focusConfirm: false,
          preConfirm: () => {
            const login = this.$swal.getPopup().querySelector("#login").value;
            const password = this.$swal.getPopup().querySelector("#password").value;
            if (!login || !password) {
              this.$swal.showValidationMessage(`Please enter login and password`);
            }
            return { login: login, password: password };
          },
        })
        .then((result) => {
          return result.value;
        });
      console.log("user:", user);
      return user;
    },
    async showVendocUrlDialog() {
      console.log("showVendocUrlDialog");
      let url = "";
      // make sure to allways close vuetify dialogs before opening
      // otherwise the inpusts can not be focused!
      if (this.$isServer) return;

      url = await this.$swal
        .fire({
          html: `
              <img src="VenDoc-logo.png" alt="vendoc logo" class="avatar" height="100">
            <input type="text" id="url" class="swal2-input front" placeholder="Url">
`,
          title: "VenDoc Url",
          titleText: "Bitte VenDoc Url angeben",
          confirmButtonText: "Url Bestätigen",
          focusConfirm: false,
          didOpen: () => {
            console.log("didOpen");
            const input = this.$swal.getContainer().querySelector("input");
            input.focus();
            // let dialogs = document.getElementsByClassName("v-dialog");
            // for (let i = 0; i < dialogs.length; i++) {
            //   dialogs[i].style.display = "none";
            // }
            this.$swal.getContainer().addClass("front");
            console.log("input:", input);
            input.addEventListener("click", (e) => {
              console.log("click", document.getElementById("url"), input);
              setTimeout(function () {
                input.focus();
              }, 100);
              input.focus();
            });
          },
          preConfirm: () => {
            const url = this.$swal.getPopup().querySelector("#url").value;
            if (!url) {
              this.$swal.showValidationMessage(`Butte Url angeben!`);
            }
            console.log("show dialogs again!");
            // let dialogs = document.getElementsByClassName("v-dialog");
            // for (let i = 0; i < dialogs.length; i++) {
            //   dialogs[i].style.display = "block";
            // }
            return { url: url };
          },
        })
        .then((result) => {
          url = result.value.url;
          return url;
          //         this.$swal.fire(
          //           `
          //   url: ${result.value.url}
          // `.trim()
          //         );
        });
      console.log("url", url);
      return url;
    },
    constructErrorMsgText(responseError) {
      let text = ": Error " + responseError.status + " (" + responseError.statusText + ")";
      if (responseError.data.length === 1) {
        text = text + " -> " + responseError.data[0];
      } else {
        console.log(responseError.data.split("\n")[0]);
      }

      return text;
    },
  },
};
</script>

<style>
/* get the required local files */
.swal2-popup {
  font-family: "Roboto", serif !important;
  z-index: 2000;
}
.swal-text {
  font-family: "Roboto", serif !important;
}
.swal-text {
  font-family: "Roboto", serif !important;
}
.front {
  z-index: 2000;
  clear: both;
}
</style>
